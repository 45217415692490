import React from 'react'
import '../Screens/Washer.css';
import HOMEHERO from '../images/HOMEHERO.jpg'
import WASHGAL from '../images/WASHGAL.jpeg'
import ACGAL from '../images/ACGAL.webp'

import { library } from '@fortawesome/fontawesome-svg-core';
import { faDoorOpen, faBolt, faCreditCard, faClock, faTools, faThumbsUp, faRupeeSign, faCalendarDay } from '@fortawesome/free-solid-svg-icons';

// Add icons to the library
library.add(faDoorOpen, faBolt, faCreditCard, faClock, faTools, faThumbsUp, faRupeeSign, faCalendarDay);
export default function Home() {
  return (
    <>
     <section id="hero" style={{ backgroundImage: `url(${HOMEHERO})` }}>
          <div className="hero-content">
            <h1>Home Appliance Service Center</h1>
            <p>Your Trusted Solution for All Appliance Repairs</p>
            <a href="tel:+918838867205" className="hero-btn">Book Service?</a>
          </div>
        </section>



        <section id="about">
  <div class="about-content">
    <h2 class="about-title">About Chennai Appliances Repair</h2>
    <p class="about-text">
      Welcome to <strong>Chennai Appliances Repair Company</strong>, your trusted partner for all your home appliance repair needs. 
      With over <strong>6+ years of experience</strong>, we specialize in providing high-quality repair and maintenance services for 
      washing machines, air conditioners, and other essential appliances. 
    </p>
    <p class="about-text">
      Our team of certified and skilled technicians is committed to delivering prompt, reliable, and affordable services. 
      We pride ourselves on being a <strong>highly rated service center</strong> in Chennai, with a strong focus on customer satisfaction. 
      Whether it's a minor issue or a major breakdown, we ensure your appliances are back in perfect working condition in no time.
    </p>
    <p class="about-text">
      Join thousands of satisfied customers who trust us for our expertise, professionalism, and dedication to excellence. 
      At Chennai Appliances Repair, we make your comfort our priority.
    </p>
    <a href="/contact" class="about-btn">Get in Touch</a>
  </div>
</section>

    



<section id="services-showcase">
  <div class="services-container">
    <div class="service-item">
    <img src={WASHGAL} alt="Gallery Image 1" className="img-fluid rounded" />
      <div class="service-content">
        <h2>Chennai Appliances Repair's Washing Machine Service</h2>
        <p>
          Our washing machine repair service ensures your appliance functions like new. 
          From drum issues to electrical faults, we provide reliable solutions to get your laundry back on track.
        </p>
      </div>
    </div>
    <div class="service-item reverse">
      <div class="service-content">
        <h2>Chennai Appliances Repair's AC Service</h2>
        <p>
          Keep your home cool with our expert AC service. 
          We handle everything from regular maintenance to complex repairs, ensuring your AC runs efficiently year-round.
        </p>
      </div>
      <img src={ACGAL} alt="Gallery Image 1" className="img-fluid rounded" />
    </div>
  </div>
</section>

<section id="our-services-section">
      <div className="services-container">
        <h2 className="services-heading">Our Services</h2>
        <p className="services-description">
          We offer high-quality home appliance services at an affordable price. 
          <strong> Our basic service charge is ₹400</strong>, and spare parts costs are additional, based on the market price. 
          <br />
          <strong>No initial payment required.</strong> Pay only after the service is completed to your satisfaction. 
          <br />
          We provide a <strong>warranty and guarantee</strong> for both spare parts and our services.
        </p>
        <p className="services-promotion">
          To ensure regular customer engagement, we actively promote our services through 
          <strong> Google Ads</strong>. We aim to provide prompt and reliable solutions to all your appliance needs.
        </p>
      </div>

      <div id="brand-disclaimer">
        <h3 className="disclaimer-heading">Multibrand Service Center</h3>
        <p className="disclaimer-content">
          Please note that we are <strong>not authorized by any specific brand</strong>. 
          However, we provide expert servicing for all major brands of home appliances. 
          <br />
          As a <strong>multibrand service center</strong>, we are equipped to handle your appliance repair needs with the utmost professionalism and care.
        </p>
      </div>
    </section>


<section id="our-best" class="our-best-section">
  <h2 class="section-title">Our Best Features</h2>
  <div class="best-features">
    <div class="feature-item">
      <i class="fas fa-door-open"></i>
      <h3>Door Step Service</h3>
      <p>Get your appliances serviced right at your doorstep for maximum convenience.</p>
    </div>
    <div class="feature-item">
      <i class="fas fa-bolt"></i>
      <h3>Quick Response</h3>
      <p>We prioritize your needs and respond swiftly to any service request.</p>
    </div>
    <div class="feature-item">
      <i class="fas fa-credit-card"></i>
      <h3>No Initial Payment</h3>
      <p>Enjoy hassle-free service with no upfront payment required.</p>
    </div>
    <div class="feature-item">
      <i class="fas fa-clock"></i>
      <h3>Timely Delivery</h3>
      <p>We ensure your appliance gets repaired and delivered back to you on time.</p>
    </div>
  </div>
</section>




<section id="benefits">
  <div class="benefits-container">
    <h2 class="benefits-title">Benefits of Chennai Appliances Repair</h2>
    <div class="benefits-cards">
      <div class="benefit-card">
        <i class="fas fa-tools"></i>
        <h3>Expert Technicians</h3>
        <p>Our team consists of highly skilled and certified professionals to handle all repairs with precision.</p>
      </div>
      <div class="benefit-card">
        <i class="fas fa-clock"></i>
        <h3>Quick Service</h3>
        <p>We prioritize your convenience by ensuring fast and reliable repair services.</p>
      </div>
      <div class="benefit-card">
        <i class="fas fa-thumbs-up"></i>
        <h3>Customer Satisfaction</h3>
        <p>Your satisfaction is our top priority, and we strive to exceed expectations with every repair.</p>
      </div>
      <div class="benefit-card">
        <i class="fas fa-rupee-sign"></i>
        <h3>Affordable Pricing</h3>
        <p>Get the best repair services at competitive prices without compromising on quality.</p>
      </div>
    </div>
  </div>
</section>









<section id="why-we-stand">
  <h2 class="section-title">Why We Stand in the Service Industry</h2>
  <div class="why-cards">
    <div class="why-card">
      <h3>Expertise & Experience</h3>
      <p>
        With over 6+ years of experience, our team is equipped to handle all appliance-related challenges with unmatched expertise.
      </p>
    </div>
    <div class="why-card">
      <h3>Customer-Centric Approach</h3>
      <p>
        We prioritize our customers' needs, ensuring satisfaction through prompt service and reliable solutions.
      </p>
    </div>
    <div class="why-card">
      <h3>Quality Assurance</h3>
      <p>
        We use high-grade tools and genuine spare parts, ensuring that every repair meets the highest standards.
      </p>
    </div>
    <div class="why-card">
      <h3>Innovative Solutions</h3>
      <p>
        Staying updated with the latest trends, we provide modern and efficient solutions for appliance repairs.
      </p>
    </div>
  </div>
</section>





<section id="opening-hours" class="opening-hours-section">
  <h2 class="section-title">Opening Hours</h2>
  <table class="opening-hours-table">
    <thead>
      <tr>
        <th>Day</th>
        <th>Hours</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><i class="fas fa-calendar-day"></i> Monday</td>
        <td>9:00 AM - 7:00 PM</td>
      </tr>
      <tr>
        <td><i class="fas fa-calendar-day"></i> Tuesday</td>
        <td>9:00 AM - 7:00 PM</td>
      </tr>
      <tr>
        <td><i class="fas fa-calendar-day"></i> Wednesday</td>
        <td>9:00 AM - 7:00 PM</td>
      </tr>
      <tr>
        <td><i class="fas fa-calendar-day"></i> Thursday</td>
        <td>9:00 AM - 7:00 PM</td>
      </tr>
      <tr>
        <td><i class="fas fa-calendar-day"></i> Friday</td>
        <td>9:00 AM - 7:00 PM</td>
      </tr>
      <tr>
        <td><i class="fas fa-calendar-day"></i> Saturday</td>
        <td>9:00 AM - 7:00 PM</td>
      </tr>
      <tr>
        <td><i class="fas fa-calendar-day"></i> Sunday</td>
        <td>Closed</td>
      </tr>
    </tbody>
  </table>
</section>




    </>
  )
}
