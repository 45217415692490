import React from 'react'
import '../Screens/Termsconditions.css';
export default function Termsconditions() {
  return (
    <>
    
    
    <section id="terms-conditions">
  <h2>Terms and Conditions of Chennai Appliances Repair</h2>
  <p>
    Welcome to <strong>Chennai Appliances Repair</strong>. By using our services, you agree to the following Terms and Conditions. Please read them carefully to ensure you understand your rights and responsibilities when availing our washing machine and AC repair services.
  </p>
  
  <h3>Services Provided</h3>
  <p>
    Chennai Appliances Repair offers professional repair services for washing machines and air conditioners. Our services include diagnostic checks, repair, and maintenance, as well as spare part replacement when necessary.
  </p>

  <h3>Customer Responsibilities</h3>
  <p>
    As a customer, you agree to provide accurate and complete information when registering for service. You are also responsible for ensuring that the appliance is accessible and ready for repair at the scheduled time.
  </p>

  <h3>Service Fees</h3>
  <p>
    A service fee is applicable for all repairs. The final cost of service may vary depending on the type of repair and any spare parts required. We will inform you of the cost before proceeding with any service.
  </p>

  <h3>Payment Terms</h3>
  <p>
    Payment for services should be made upon completion of the repair. We accept cash and other payment methods as outlined on our website.
  </p>

  <h3>Limitation of Liability</h3>
  <p>
    While we strive to provide the best possible service, Chennai Appliances Repair is not liable for any damages, losses, or delays arising from the failure or malfunction of appliances. Our liability is limited to the repair service provided.
  </p>

  <h3>Changes to Terms</h3>
  <p>
    We reserve the right to modify these Terms and Conditions at any time. Any changes will be updated on this page, and your continued use of our services will signify acceptance of the revised terms.
  </p>

  <h3>Contact Us</h3>
  <p>
    If you have any questions regarding our Terms and Conditions, feel free to contact us through the details on our website. We are here to assist you with any concerns.
  </p>
</section>

    
    
    
    </>
  )
}
