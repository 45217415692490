import React from 'react'

export default function About() {
  return (
  <>
  
  
  <header>
        <h1>Welcome to Chennai Appliances Repair</h1>
        <p>Your One-Stop Solution for Appliances Repairs in Chennai</p>
    </header>

    <section id="about">
        <h2 class="section-title">About Us</h2>
        <p>Chennai Appliances Repair is the leading home Appliances repair service provider in Chennai. With years of expertise, we specialize in servicing washing machines, refrigerators, air conditioners, microwave ovens, dishwashers, and water purifiers. Our team of certified technicians ensures top-notch repair solutions with guaranteed satisfaction.</p>
    </section>

    <section id="why-choose-us">
        <h2 class="section-title">Why Choose Us</h2>
        <div class="card-container">
            <div class="card">
                <h3>Quick Service</h3>
                <p>We provide same-day service to ensure your Appliances are up and running without delay.</p>
            </div>
            <div class="card">
                <h3>Trusted Technicians</h3>
                <p>Our team consists of skilled, certified professionals who deliver reliable service every time.</p>
            </div>
            <div class="card">
                <h3>Affordable Pricing</h3>
                <p>We offer competitive rates with no hidden costs, ensuring value for money.</p>
            </div>
            <div class="card">
                <h3>Comprehensive Repairs</h3>
                <p>From small fixes to major repairs, we handle it all with precision and care.</p>
            </div>
        </div>
    </section>

    <section id="special-features">
        <h2 class="section-title">Our Special Features</h2>
        <div class="special-section">
            <h3>Fast Delivery</h3>
            <p>We not only repair your Appliances but also ensure swift delivery for your convenience.</p>
        </div>
        <div class="special-section">
            <h3>Warranty on Services</h3>
            <p>We offer a one-month service warranty and up to six months warranty on spare parts.</p>
        </div>
        <div class="special-section">
            <h3>Customer Support</h3>
            <p>Our friendly customer care team is available to assist you 24/7.</p>
        </div>
    </section>

    <section id="benefits">
        <h2 class="section-title">Benefits of Choosing Us</h2>
        <div class="card-container">
            <div class="card">
                <h3>Expert Diagnosis</h3>
                <p>We identify the root cause of Appliances issues and fix them effectively.</p>
            </div>
            <div class="card">
                <h3>Genuine Spare Parts</h3>
                <p>We use only original parts to maintain the performance and longevity of your Appliances.</p>
            </div>
            <div class="card">
                <h3>Doorstep Service</h3>
                <p>Our technicians arrive at your home, making the repair process hassle-free for you.</p>
            </div>
        </div>
    </section>

    <section id="contact">
        <h2 class="section-title">Get in Touch</h2>
        <p>If you have an Appliances in need of repair, don’t hesitate to contact us. Call us at <strong>+91 12345 67890</strong> or email us at <strong>support@chennaiAppliancesrepair.com</strong>. You can also book your service online through our website.</p>
    </section>
  
  </>
  )
}
