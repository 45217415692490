import React from 'react'
import '../Screens/Acservice.css';
import ACHOME from '../images/ACHOME.jpg'
import ACGAL1 from '../images/ACGAL1.jpg'
import ACGAL2 from '../images/ACGAL2.jpg'

import { library } from '@fortawesome/fontawesome-svg-core';
import { faDoorOpen, faBolt, faCreditCard, faClock, faTools, faThumbsUp, faRupeeSign, faCalendarDay } from '@fortawesome/free-solid-svg-icons';

// Add icons to the library
library.add(faDoorOpen, faBolt, faCreditCard, faClock, faTools, faThumbsUp, faRupeeSign, faCalendarDay);


export default function Acservice() {
  return (
     <>
            <section id="hero" style={{ backgroundImage: `url(${ACHOME})` }}>
                 <div className="hero-content">
                   <h1>AC Service Center</h1>
                   <p>Your Trusted Solution for All Appliance Repairs</p>
                   <a href="tel:+918838867205" className="hero-btn">Book Service?</a>
                 </div>
               </section>
       
       
       
               <section id="about-ac-service">
  <div class="about-content">
    <h2 class="about-title">About Chennai Appliances AC Service</h2>
    <p class="about-text">
      Welcome to <strong>Chennai Appliances AC Service</strong>, your trusted partner for expert air conditioner repair and maintenance. 
      With over <strong>6+ years of specialized experience</strong>, we are dedicated to providing top-quality service for all types of air conditioners, 
      including split ACs, window ACs, and portable ACs.
    </p>
    <p class="about-text">
      Our team of certified and skilled technicians specializes in diagnosing and resolving issues such as cooling inefficiency, gas leakage, 
      compressor problems, and unusual noises. As a <strong>highly rated service center</strong> in Chennai, we focus on delivering prompt, affordable, 
      and reliable solutions tailored to your needs.
    </p>
    <p class="about-text">
      At <strong>Chennai Appliances AC Service</strong>, we ensure your air conditioner is restored to its peak performance using genuine spare parts with 
      warranty coverage. Our commitment to excellence has earned the trust of thousands of satisfied customers across the city.
    </p>
    <p class="about-text">
      Whether it’s a minor tune-up or a major repair, we make your comfort and satisfaction our priority. Trust us to provide the best care for your air conditioner.
    </p>
    <a href="/contact" class="about-btn">Book AC Service</a>
  </div>
</section>

       
           
       
       
       
       <section id="services-showcase">
         <div class="services-container">
           <div class="service-item">
           <img src={ACGAL1} alt="Gallery Image 1" className="img-fluid rounded" />
             <div class="service-content">
             <h2>Chennai Appliances Repair's AC Service</h2>
<p>
  At <strong>Chennai Appliances Repair</strong>, we specialize in expert AC services tailored to meet your needs. 
  Whether it's fixing cooling issues, resolving leakage problems, addressing electrical faults, or ensuring smooth airflow, 
  our skilled technicians ensure your AC operates efficiently. 
  Experience comfort and coolness with our reliable and prompt solutions, designed to restore your AC to peak performance.
</p>

    
             </div>
           </div>
           <div class="service-item reverse">
             <div class="service-content">
             <h2>Chennai Appliances AC Service</h2>
<p>
  At <strong>Chennai Appliances Repair</strong>, we are dedicated to providing top-notch AC repair and maintenance services. 
  From resolving cooling issues, leakage problems, and airflow blockages to diagnosing and fixing electrical faults, 
  our experienced technicians use advanced tools and techniques to ensure your air conditioner is back in optimal working condition. 
  We service all AC brands and models, offering a seamless, affordable, and reliable experience. 
  Choose us for quick turnarounds, guaranteed repairs, and exceptional customer care—making your space cool and comfortable again.
</p>

             </div>
             <img src={ACGAL2} alt="Gallery Image 1" className="img-fluid rounded" />
           </div>
         </div>
       </section>
       
       <section id="our-services-section">
             <div className="services-container">
               <h2 className="services-heading">Our Services</h2>
               <p className="services-description">
                 We offer high-quality home appliance services at an affordable price. 
                 <strong> Our basic service charge is ₹400</strong>, and spare parts costs are additional, based on the market price. 
                 <br />
                 <strong>No initial payment required.</strong> Pay only after the service is completed to your satisfaction. 
                 <br />
                 We provide a <strong>warranty and guarantee</strong> for both spare parts and our services.
               </p>
               <p className="services-promotion">
                 To ensure regular customer engagement, we actively promote our services through 
                 <strong> Google Ads</strong>. We aim to provide prompt and reliable solutions to all your appliance needs.
               </p>
             </div>
       
             <div id="brand-disclaimer">
               <h3 className="disclaimer-heading">Multibrand Service Center</h3>
               <p className="disclaimer-content">
                 Please note that we are <strong>not authorized by any specific brand</strong>. 
                 However, we provide expert servicing for all major brands of home appliances. 
                 <br />
                 As a <strong>multibrand service center</strong>, we are equipped to handle your appliance repair needs with the utmost professionalism and care.
               </p>
             </div>
           </section>
       
       
       <section id="our-best" class="our-best-section">
         <h2 class="section-title">Our Best Features</h2>
         <div class="best-features">
           <div class="feature-item">
             <i class="fas fa-door-open"></i>
             <h3>Door Step Service</h3>
             <p>Get your appliances serviced right at your doorstep for maximum convenience.</p>
           </div>
           <div class="feature-item">
             <i class="fas fa-bolt"></i>
             <h3>Quick Response</h3>
             <p>We prioritize your needs and respond swiftly to any service request.</p>
           </div>
           <div class="feature-item">
             <i class="fas fa-credit-card"></i>
             <h3>No Initial Payment</h3>
             <p>Enjoy hassle-free service with no upfront payment required.</p>
           </div>
           <div class="feature-item">
             <i class="fas fa-clock"></i>
             <h3>Timely Delivery</h3>
             <p>We ensure your appliance gets repaired and delivered back to you on time.</p>
           </div>
         </div>
       </section>
       
       
       
       
       <section id="benefits">
         <div class="benefits-container">
           <h2 class="benefits-title">Benefits of Chennai Appliances Repair</h2>
           <div class="benefits-cards">
             <div class="benefit-card">
               <i class="fas fa-tools"></i>
               <h3>Expert Technicians</h3>
               <p>Our team consists of highly skilled and certified professionals to handle all repairs with precision.</p>
             </div>
             <div class="benefit-card">
               <i class="fas fa-clock"></i>
               <h3>Quick Service</h3>
               <p>We prioritize your convenience by ensuring fast and reliable repair services.</p>
             </div>
             <div class="benefit-card">
               <i class="fas fa-thumbs-up"></i>
               <h3>Customer Satisfaction</h3>
               <p>Your satisfaction is our top priority, and we strive to exceed expectations with every repair.</p>
             </div>
             <div class="benefit-card">
               <i class="fas fa-rupee-sign"></i>
               <h3>Affordable Pricing</h3>
               <p>Get the best repair services at competitive prices without compromising on quality.</p>
             </div>
           </div>
         </div>
       </section>
       
       
       
       
       
       
       
       
       
       <section id="why-we-stand">
         <h2 class="section-title">Why We Stand in the Service Industry</h2>
         <div class="why-cards">
           <div class="why-card">
             <h3>Expertise & Experience</h3>
             <p>
               With over 6+ years of experience, our team is equipped to handle all appliance-related challenges with unmatched expertise.
             </p>
           </div>
           <div class="why-card">
             <h3>Customer-Centric Approach</h3>
             <p>
               We prioritize our customers' needs, ensuring satisfaction through prompt service and reliable solutions.
             </p>
           </div>
           <div class="why-card">
             <h3>Quality Assurance</h3>
             <p>
               We use high-grade tools and genuine spare parts, ensuring that every repair meets the highest standards.
             </p>
           </div>
           <div class="why-card">
             <h3>Innovative Solutions</h3>
             <p>
               Staying updated with the latest trends, we provide modern and efficient solutions for appliance repairs.
             </p>
           </div>
         </div>
       </section>
       
       
       
       
       
       <section id="opening-hours" class="opening-hours-section">
         <h2 class="section-title">Opening Hours</h2>
         <table class="opening-hours-table">
           <thead>
             <tr>
               <th>Day</th>
               <th>Hours</th>
             </tr>
           </thead>
           <tbody>
             <tr>
               <td><i class="fas fa-calendar-day"></i> Monday</td>
               <td>9:00 AM - 7:00 PM</td>
             </tr>
             <tr>
               <td><i class="fas fa-calendar-day"></i> Tuesday</td>
               <td>9:00 AM - 7:00 PM</td>
             </tr>
             <tr>
               <td><i class="fas fa-calendar-day"></i> Wednesday</td>
               <td>9:00 AM - 7:00 PM</td>
             </tr>
             <tr>
               <td><i class="fas fa-calendar-day"></i> Thursday</td>
               <td>9:00 AM - 7:00 PM</td>
             </tr>
             <tr>
               <td><i class="fas fa-calendar-day"></i> Friday</td>
               <td>9:00 AM - 7:00 PM</td>
             </tr>
             <tr>
               <td><i class="fas fa-calendar-day"></i> Saturday</td>
               <td>9:00 AM - 7:00 PM</td>
             </tr>
             <tr>
               <td><i class="fas fa-calendar-day"></i> Sunday</td>
               <td>Closed</td>
             </tr>
           </tbody>
         </table>
       </section>
    
       </>
  )
}
