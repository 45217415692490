import React, { useState } from "react";
import { Link } from "react-router-dom";

import './Navbar.css';

function Navbar() {
  // State to manage the dropdown menu open/close status
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Function to toggle the dropdown visibility
  const handleDropdownToggle = () => {
    setIsDropdownOpen(prevState => !prevState);
  };

  // Function to collapse the navbar when a link is clicked
  const handleLinkClick = () => {
    const navbarCollapse = document.getElementById('navbarNav');
    if (navbarCollapse && navbarCollapse.classList.contains('show')) {
      navbarCollapse.classList.remove('show');
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container">
        <Link className="navbar-brand" to="/">
          Chennai Appliances <br/> Repair
        </Link>
        <button
          className="navbar-toggler ms-auto"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/" onClick={handleLinkClick}>
                Home
              </Link>
            </li>


            <li className="nav-item">
              <Link className="nav-link" to="/about" onClick={handleLinkClick}>
                About
              </Link>
            </li>

            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded={isDropdownOpen ? "true" : "false"}  // Dynamically set aria-expanded
                onClick={handleDropdownToggle}
              >
                Services
              </Link>
              <ul
                className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`} // Fixed dynamic className syntax
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <Link
                    className="dropdown-item"
                    to="/Washer"
                    onClick={handleLinkClick}
                  >
                    Washing Machine Repair
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/Acservice"
                    onClick={handleLinkClick}
                  >
                    AC Maintenance
                  </Link>
                </li>
               
              </ul>
            </li>
          
            <li className="nav-item">
              <Link className="nav-link" to="/contact" onClick={handleLinkClick}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
