import React from 'react';


export default function Contact() {
  return (
    <>
      <header>
        <h1>Contact Us - Chennai Appliancess Repair</h1>
        <p>We Are Here to Help You with All Your Appliances Needs</p>
      </header>

      <section id="contact-info">
        <h2 className="section-title">Contact Information</h2>
        <div className="contact-card">
          <div className="card">
            <h3>Our Office</h3>
            <p>95, M G R Nagar, Nandhiyambakkam Village, Nandhiyambakkam Post-600120</p>
          </div>
          <div className="card">
            <h3>Phone</h3>
            <p>+918838867205</p>
          </div>
          <div className="card">
            <h3>Email</h3>
            <p>chennaiappliancerepairs@gmail.com</p>
          </div>
          <div className="card">
            <h3>Business Hours</h3>
            <p>
              Monday to Saturday: 9:00 AM - 8:00 PM
              <br />
              Sunday: Closed
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
