import React from 'react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";


import './App.css';
import ScrollToTopButton from '../src/Screens/ScrollToTopButton';
import Navbar from '../src/Components/Navbar';
import Footer from '../src/Components/Footer';
import Home from '../src/Screens/Home';
import About from '../src/Screens/About';
import Acservice from '../src/Screens/Acservice';
import Washer from '../src/Screens/Washer';
import Contact from '../src/Screens/Contact';
import Privacypolicy from '../src/Screens/Privacypolicy';
import Termsconditions from '../src/Screens/Termsconditions';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faDoorOpen, faBolt, faCreditCard, faClock, faTools, faThumbsUp, faRupeeSign, faCalendarDay } from '@fortawesome/free-solid-svg-icons';

// Add icons to the library
library.add(faDoorOpen, faBolt, faCreditCard, faClock, faTools, faThumbsUp, faRupeeSign, faCalendarDay);

function App() {
  return (
   <>
   <Router>
    <Navbar/>
<ScrollToTopButton/>
    
<Routes>
<Route path="/" element={<Home/>} />
<Route path="/About" element={<About/>} />
<Route path="/Washer" element={<Washer/>} />
<Route path="/Acservice" element={<Acservice/>} />
<Route path="/Contact" element={<Contact/>} />
<Route path="/Privacypolicy" element={<Privacypolicy/>} />
<Route path="/Termsconditions" element={<Termsconditions/>} />


</Routes>

<Footer/>
   </Router>
   
   
   <section id="fixed-phone-button">
   <a href="tel:+918838867205" className="phone-call-button">
     Call Us Now:8838867205
   </a>
 </section>
   </>
  );
}

export default App;
