import React from 'react'
import '../Screens/Privacypolicy.css';
export default function Privacypolicy() {
  return (
  <>
  
  
  
  <section id="privacy-policy">
  <h2>Privacy Policy of Chennai Appliances Repair</h2>
  <p>
    At <strong>Chennai Appliances Repair</strong>, we prioritize the privacy and security of our customers. This Privacy Policy outlines how we collect, use, and protect your personal information when you avail of our washing machine and AC services.
  </p>
  <h3>Information We Collect</h3>
  <p>
    We collect basic personal information from our customers, which includes:
    <ul>
      <li>Name</li>
      <li>Address</li>
      <li>Phone Number</li>
    </ul>
  </p>
  
  <h3>Why We Collect Your Information</h3>
  <p>
    The information we collect is used solely for the purpose of following up with our customers and providing timely and efficient service. We ensure that our team can contact you for scheduling repairs, offering support, and ensuring the best possible service experience.
  </p>

  <h3>How We Protect Your Information</h3>
  <p>
    Your information is maintained in Google Sheets, which we trust for its security. We take precautions to protect your data, and we do not share, sell, or disclose it to any third parties. Your privacy is important to us, and we are committed to safeguarding your personal information.
  </p>

  <h3>Data Security</h3>
  <p>
    While we use Google Sheets to store customer information securely, we advise our customers to contact us if they have any concerns regarding the safety of their personal data. We take every possible step to ensure your information is handled with care.
  </p>

  <h3>Contact Us</h3>
  <p>
    If you have any questions or concerns about how we handle your personal information, feel free to reach out to us through our contact details on the website. We are happy to assist you with any queries.
  </p>
</section>

  
  
  </>
  )
}
