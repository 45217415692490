import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import './Footer.css';

export default function Footer() {
  return (
    <>
      <footer
        id="footer-section"
        style={{
          backgroundColor: '#2c3e50',
          color: '#ecf0f1',
          padding: '40px 20px',
          textAlign: 'center',
        }}
      >
        <div className="footer-content">
          {/* Quick Links Section */}
          <div
            className="quick-links"
            style={{ marginBottom: '20px' }}
          >
            <h3
              style={{
                fontSize: '1.5rem',
                marginBottom: '15px',
              }}
            >
              Quick Links
            </h3>
            <ul
              style={{
                listStyle: 'none',
                padding: 0,
                display: 'flex',
                justifyContent: 'center',
                gap: '20px',
              }}
            >
              <li>
                <Link
                  to="/Privacypolicy" // Use Link instead of a tag for internal routing
                  style={{
                    color: '#ecf0f1',
                    textDecoration: 'none',
                    fontSize: '1rem',
                  }}
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/Termsconditions" // Use Link instead of a tag for internal routing
                  style={{
                    color: '#ecf0f1',
                    textDecoration: 'none',
                    fontSize: '1rem',
                  }}
                >
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link
                  to="/Contact" // Corrected Contact link
                  style={{
                    color: '#ecf0f1',
                    textDecoration: 'none',
                    fontSize: '1rem',
                  }}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          {/* Map Location Section */}
          <div
            className="map-location"
            style={{ marginTop: '20px' }}
          >
            <h3
              style={{
                fontSize: '1.5rem',
                marginBottom: '15px',
              }}
            >
              Our Location
            </h3>
            <p>
              95 M G R Nagar, Nandhiyambakkam Village, Nandhiyambakkam Post-600120
            </p>
            <div
              style={{
                width: '100%',
                height: '300px',
                borderRadius: '8px',
                overflow: 'hidden',
                marginTop: '15px',
              }}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15533.813540999177!2d80.2754303310582!3d13.259583578251215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5270add78d14b5%3A0x4e8f7be1f121007d!2sNandiambakkam%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1734092258786!5m2!1sen!2sin"
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>

          {/* Footer Bottom Section */}
          <p
            style={{
              marginTop: '30px',
              fontSize: '0.9rem',
            }}
          >
            &copy; 2024 Chennai Appliances Repair. All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
}
